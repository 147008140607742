import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {Helmet} from "react-helmet";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const ContactSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Contact Us',
    paragraph: 'Should you have any questions, feel free to reach us through...'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta name='description' content="Should you have any questions, feel free to reach us through..." />
      </Helmet>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="contact-outer center-content tiles-wrap">

            <div className="contact-inner tiles-item">
              <a href="https://www.instagram.com/foodmatesss/">
                <svg
                  fill='lightgray'
                  width="100"
                  height="100"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg">
                  <title>Instagram</title>
                  <g>
                    <circle cx="12.145" cy="3.892" r="1" />
                    <path
                      d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                    <path
                      d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
                  </g>
                </svg>
                <p className='contact-text'>Instagram</p>
                <p className='contact-text-small'>@foodmatesss</p>
              </a>
            </div>

            <div className="contact-inner tiles-item">
              <a href="https://www.facebook.com/foodmatesss/">
                <svg
                  fill='lightgray'
                  width="100"
                  height="100"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg">
                  <title>Facebook</title>
                  <path
                    d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" />
                </svg>
                <p className='contact-text'>Facebook</p>
                <p className='contact-text-small'>Foodmatesss</p>
              </a>
            </div>

            <div className="contact-inner tiles-item">
              <a href="mailto:info@foodmatesss.com">
                <svg
                  fill='lightgray'
                  width="100"
                  height="100"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg">
                  <title>Instagram</title>
                  <g>
                    <path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm17-15.1L7 13.75V37h34V13.75Zm0-3L40.8 11H7.25ZM7 13.75V11v26Z"/>
                  </g>
                </svg>
                <p className='contact-text'>Email</p>
                <p className='contact-text-small'>info@foodmatesss.com</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ContactSplit.propTypes = propTypes;
ContactSplit.defaultProps = defaultProps;

export default ContactSplit;