import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

const propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
}

const defaultProps = {
  href: '',
  text: '',
}

const ToolButton = ({
  href,
  text,
  ...props
}) => {
  return (
      <div className="container-xs">
        <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
          <Button
          href={href}
          style={{width: "80%", color: "black", backgroundColor: "white", marginBlock: "1%", fontWeight: "bold"}}
          >
          {text}
          </Button>
        </p>
      </div>
  );
}

ToolButton.propTypes = propTypes;
ToolButton.defaultProps = defaultProps;

export default ToolButton;