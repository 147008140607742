import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import ToolButton from '../elements/ToolButton';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Toollist = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <ToolButton text="香港的士收費計算機" href="/taxi-calculator" />
            <ToolButton text="香港的士地方筆試" href="/taxi-exam" />
            <ToolButton text="香港急症室等候時間" href="/ae-waiting" />
            <ToolButton text="支票中英文金額轉換器" href="/cheque-writing" />
            <ToolButton text="Square Feet to Square Meter (ft² to m²)" href="/square-feet-to-square-meter" />
            <ToolButton text="Square Meter to Square Feet (m² to ft²)" href="/square-meter-to-square-feet" />
            <ToolButton text="Feet to Meters (ft to m)" href="/feet-to-meters" />
            <ToolButton text="Meters to Feet (m to ft)" href="/meters-to-feet" />
            <ToolButton text="Date Calculator" href="/date-calculator" />
            <ToolButton text="Merge PDF" href="/mergepdf" />
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Toollist.propTypes = propTypes;
Toollist.defaultProps = defaultProps;

export default Toollist;