import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { InputAdornment, Input, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomFeaturesTiles from './CustomFeaturesTiles';
import {Helmet} from "react-helmet";
import {EXPORT_QUESTION_LIST, EXPORT_ALL_TYPE} from './Allquestion'
import { Button } from 'react-bootstrap';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const TaxiExam = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '香港的士地方筆試',
    paragraph: '收錄運輸署360條地方試題,自動紀錄做錯嘅題目, 隨時複習, 可按分類做題目, 360條地方試題一覽'
  };

  const features = [
    {"title": "香港的士", "description": "香港的士是香港其中一種主要交通工具,的士分為三種,分別為市區的士,新界的士及大嶼山的士"},
    {"title": "市區的士", "description": "只在市區,新界及大嶼山部分地區(不能進入石甲門以南的大嶼山南部地區)行走"},
    {"title": "新界的士", "description": "只在新界西北及東北行走,不可在荃灣,沙田及將軍澳自由上落客,但亦可在部分指定的士站上落客(如 順利邨,荃灣地鐵站,青衣機鐵站,沙田威爾斯醫院,沙田醫院,坑口站,新寶城,香港國際機場,港珠澳大橋)"},
    {"title": "大嶼山的士", "description": "只在大嶼山行走"},
    {"title": "收費", "description": "市區的士,新界的士及大嶼山的士的收費準則各有不同,市區的士$27起標,新界的士$23.5起標,大嶼山的士$22起標"},
    {"title": "附加費", "description": "香港的士會對以下服務加收附加費,包括行李(每件$6),動物(每隻$5),及隧道費及回程費"},
    {"title": "紅磡海底隧道", "description": "單程隧道費: $10 隧道費連回程費: $20"},
    {"title": "東區海底隧道", "description": "單程隧道費: $25 隧道費連回程費: $40"},
    {"title": "西區海底隧道", "description": "單程隧道費: $70 隧道費連回程費: $85"},
    {"title": "香港仔隧道", "description": "隧道費: $5"},
    {"title": "城門隧道", "description": "隧道費: $5"},
    {"title": "尖山隧道", "description": "隧道費: $8"},
    {"title": "獅子山隧道", "description": "隧道費: $8"},
    {"title": "大老山隧道", "description": "隧道費: $20"},
    {"title": "大欖隧道", "description": "隧道費: $55"},
  ]

  const question_type = 'All'

  const [answering, setAnswering] = useState(true)
  const [finished, setFinished] = useState(false)

  const [taxiquestionarray, setTaxiquestionarray] = useState([])

  const [currentquestion, setCurrentquestion] = useState(null)
  const [currentquestionindex, setCurrentquestionindex] = useState(0)

  const [currentansarray, setCurrentansarray] = useState([])
  const [currentansindex, setCurrentansindex] = useState(null)

  const [recordans, setRecordans] = useState([])

  const [allansarrarr, setAllansarrarr] = useState([])
  const [reviewansindex, setReviewansindex] = useState(null)

  const [marks, setMarks] = useState(null)
  const [markedlist, setMarkedlist] = useState([])


  function genNorepeatarray(max, arraylength) {
      const start = 0
      var nums = Array(max - start ).fill().map((_, idx) => start + idx),
          ranNums = [],
          i = nums.length,
          j = 0;

      while (i--) {
          j = Math.floor(Math.random() * (i+1));
          ranNums.push(nums[j]);
          nums.splice(j,1);
      }
      return ranNums.slice(0, arraylength)
  }

  function renderquestionarr() {
      if (question_type == "All") {
          var question_length = 360
          const numberArray = genNorepeatarray(question_length, 20)
          console.log(numberArray)
          var temparr = EXPORT_QUESTION_LIST.map((data, index) => {if (numberArray.includes(index)) {return data}}).filter(function( element ) {return element !== undefined;});
      } else {
          var temp_question_array = EXPORT_QUESTION_LIST.filter((item) => item['種類'] == question_type)
          var question_length = temp_question_array.length
          const numberArray = genNorepeatarray(question_length, 20)
          var temparr = temp_question_array.map((data, index) => {if (numberArray.includes(index)) {return data}}).filter(function( element ) {return element !== undefined;});
      }
      let temptemparr = temparr.map((data) => shuffleArray({'地方 (問題)': data['地方 (問題)'], '種類': data['種類'], '位置(答案)': data['位置(答案)'], 'ans_list': shuffleArray(data['ans_list'])}))
      setTaxiquestionarray(temptemparr)
  }

  function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
      return array
  }

  async function nextbuttonaction() {

      let tempansindex = currentansindex
      if (tempansindex == null) {
          tempansindex = false
      }

      let temprecord = recordans.concat(tempansindex)
      setRecordans(temprecord)

      if (currentquestionindex != 19) {
          setCurrentquestionindex(currentquestionindex+1)
          setCurrentansindex(null)
      } else {
          setAnswering(false)
          markpaper(temprecord)
          setFinished(true)
          setReviewansindex(0)
      }
  }

  function nextbuttonatreview() {
      if (reviewansindex== 19) {
          clearQuestion()
      } else {
      setReviewansindex(reviewansindex+1)
      }
  }

  async function markpaper(array) {
      const temp_markedlist = array.map((data, index) => {if (data==null) {return false} else {return (allansarrarr[index][data] == taxiquestionarray[index]['位置(答案)'])}})
      const tempmarks = (temp_markedlist.filter(item => item === true).length)
      setMarks(tempmarks)
      setMarkedlist(temp_markedlist)
  }

  function clearQuestion() {
    setAnswering(true)
    setFinished(false)
    setTaxiquestionarray([])
    setCurrentquestion(null)
    setCurrentquestionindex(0)
    setCurrentansindex(0)
    setCurrentansarray([])
    setCurrentansindex(null)
    setRecordans([])
    setAllansarrarr([])
    setReviewansindex(null)
    setMarks(null)
    setMarkedlist([])
    renderquestionarr()
  }

  useEffect(() => {
      renderquestionarr()
  }, [])

  useEffect(() => {
      if (taxiquestionarray != []) {
          let currentquestionarr = taxiquestionarray[currentquestionindex]
          if (currentquestionarr != null) {
          let temp_ans_arry = currentquestionarr['ans_list']
          let new_temp_ans_array = shuffleArray(temp_ans_arry)
          setCurrentquestion(currentquestionarr)
          setCurrentansarray(new_temp_ans_array)

          let temp_allansarrarr = allansarrarr.concat([new_temp_ans_array])
          setAllansarrarr(temp_allansarrarr)
          }
      }
  }, [taxiquestionarray, currentquestionindex])




  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>香港的士地方筆試</title>
        <meta name='description' content="收錄運輸署360條地方試題,自動紀錄做錯嘅題目, 隨時複習, 可按分類做題目, 360條地方試題一覽" />
      </Helmet>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="contact-outer center-content tiles-wrap">
            {answering &&
            <Container>
              <Row>
                <Col>
                  <p>{currentquestionindex+1}. {currentquestion && currentquestion['地方 (問題)']}</p>
                </Col>
              </Row>
                {currentansarray.map((item, index) => {return(
                  <Row style={{marginBlock: "1%"}}>
                    <Col>
                      <Button 
                      onClick={()=> setCurrentansindex(index)}
                        style={{width: "100%", color: index==currentansindex ? "white" :"black", backgroundColor: index==currentansindex ? "#007bff" : "white"}}
                      >
                        {item}
                      </Button>
                    </Col>
                  </Row>
                )})}
              <Row>
                <Col>
                  <Button onClick={() => nextbuttonaction()}>
                    {currentquestionindex==19 ? '完成考試' : '確定'}
                  </Button>
                </Col>
              </Row>
            </Container>
            }
            {finished && 
            <Container>
              <Row>
                <Col>
                  <h1>{marks}/20</h1>
                </Col>
              </Row>

              <Row>
                <Col>
                  <p>{reviewansindex+1}. {taxiquestionarray && taxiquestionarray[reviewansindex]['地方 (問題)']}</p>
                </Col>
              </Row>
                {taxiquestionarray && taxiquestionarray[reviewansindex]['ans_list'].map((item, index) => {return(
                  <Row style={{marginBlock: "1%"}}>
                    <Col>
                      <Button 
                        style={{width: "100%", color: (item==taxiquestionarray[reviewansindex]['位置(答案)'] | (recordans[reviewansindex]!=false && index==recordans[reviewansindex]))  ? "white" : "black", backgroundColor: item==taxiquestionarray[reviewansindex]['位置(答案)'] ? "#679e37" : (recordans[reviewansindex]!=false && index==recordans[reviewansindex]) ? "red" :"white"}}
                      >
                        {item}
                      </Button>
                    </Col>
                  </Row>
                )})}
              <Row>
                <Col>
                  <Button style={{marginBlock: "1%"}} onClick={() => nextbuttonatreview()}>
                    {reviewansindex== 19 ? '重新考試' : '下一條'}
                  </Button>
                </Col>
              </Row>

              <Row style={{marginBlock: "1%"}}>
                {Array.from(Array(10).keys()).map(item => {return(
                  <Col>
                    <Button onClick={() => setReviewansindex(item)} style={{width: "100%", color: "white", backgroundColor: markedlist[item]==false ? "red" : "#679e37"}}>{item+1}</Button>
                  </Col>
                )})}
              </Row>
              <Row style={{marginBlock: "1%"}}>
                {Array.from(Array(10).keys()).map(item => {return(
                  <Col>
                    <Button onClick={() => setReviewansindex(item+10)} style={{width: "100%", color: "white", backgroundColor: markedlist[item+10]==false ? "red" : "#679e37"}}>
                      {item+11}
                    </Button>
                  </Col>
                )})}
              </Row>
            </Container>}
          </div>



          <div className="contact-outer center-content tiles-wrap">
            <CustomFeaturesTiles features={features} />
          </div>
        </div>
      </div>
    </section>
  );
}

TaxiExam.propTypes = propTypes;
TaxiExam.defaultProps = defaultProps;

export default TaxiExam;