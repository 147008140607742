import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {Helmet} from "react-helmet";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const AboutSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Who are we?',
    paragraph: 'We provide quality and user-friendly online converters and tools for everyone to use.'
  };

  const sectionHeader2 = {
    title: 'What do we make?',
    paragraph: 'Our products include Square Feet to Square Meter Converter, Square Meter to Square Feet Converter, Feet to Meters Converter, Meters to Feet Converter, Date Calculator and PDF Merger.'
  };

  const sectionHeader3 = {
    title: 'Wanna ask us smething?',
    paragraph: 'Should you have any questions, feel free to reach us at info@foodmatesss.com'
  };

  const sectionHeader4 = {
    title: 'Enjoy!',
    paragraph: 'Hope you enjoy our products!'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
        <meta name='description' content="We provide quality and user-friendly online converters and tools for everyone to use. Our products include Square Feet to Square Meter Converter, Square Meter to Square Feet Converter, Feet to Meters Converter, Meters to Feet Converter, Date Calculator and PDF Merger. Hope you enjoy our products!" />
      </Helmet>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content  reveal-from-bottom" />
          <SectionHeader data={sectionHeader2} className="center-content  reveal-from-bottom" />
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/friends-lunch.jpg')}
            alt="friends eating lunch together"
            width={696}
            height={504} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeader3} className="center-content  reveal-from-bottom" />
            <SectionHeader data={sectionHeader4} className="center-content  reveal-from-bottom" />
          </div>
        </div>
      </div>
    </section>
  );
}

AboutSplit.propTypes = propTypes;
AboutSplit.defaultProps = defaultProps;

export default AboutSplit;