import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputAdornment, Input, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CustomFeaturesTiles from './CustomFeaturesTiles';
import {Helmet} from "react-helmet";

import { format } from 'date-fns'
import Datetime from 'react-datetime';
import "./react-datetime.css";
import moment from 'moment';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const DateCalculator = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [year, setYear] = useState("")
  const [month, setMonth] = useState("")
  const [day, setDay] = useState("")
  const [addYear, setAddYear] = useState("")
  const [addMonth, setAddMonth] = useState("")
  const [addDay, setAddDay] = useState("")
  const [inputDate, setInputDate] = useState("")
  const [outputDate, setOutputDate] = useState("")

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Date Calculator',
    paragraph: 'Add years, months or days to a date'
  };

  const features = [
    {"title": "Datetime calculator", "description": "A tool to help you add years/months/days to a date."},
    {"title": "Usage", "description": "Use the datetime picker to pick a date, or input the date manully, then input the years/months/days you want to add. A final date is then calculated."},
    {"title": "Conversion Table", "description": "You can make reference to the conversion table above."},
    {"title": "Completely Free", "description": "Our applications are completely free. Feel free to use our applications! :)"},
    {"title": "User-Friendly", "description": "Our applications are designed to be as simple as possible. Everyone can get started to use our applications in seconds."},
    {"title": "usefulconverter.com", "description": "You can find various useful converter and tools on usefulconverter.com"},
  ]

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function addMonths(date, months){
    var newDate = new Date(date.setMonth(date.getMonth()+months));
    return newDate
  }


  function addYears(date, years){
    var newDate = new Date(date.setFullYear(date.getFullYear()+years));
    return newDate
  }

  useEffect(() => {
    if ((isNaN(parseInt(year)))|(isNaN(parseInt(month)))|(isNaN(parseInt(day)))){
        setOutputDate()
        return
    } else {
        if (parseInt(month)>12 | parseInt(month)<1 | parseInt(day)<1 | parseInt(day)>31){
            setOutputDate()
            return
        } else {
            var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
            if(parseInt(year) % 400 == 0 || (parseInt(year) % 100 != 0 && parseInt(year) % 4 == 0)){
              monthLength[1] = 29
            }
            if (day > monthLength[month-1]){
              setOutputDate()
              return
            }

            var tempOutputDate = new Date(year, parseInt(month)-1, day)

            setInputDate(tempOutputDate)

            if (isNaN(parseInt(addYear)) == false){
              tempOutputDate = addYears(tempOutputDate, parseInt(addYear))
            }
            if (isNaN(parseInt(addMonth)) == false){
              tempOutputDate = addMonths(tempOutputDate, parseInt(addMonth))
            }
            if (isNaN(parseInt(addDay)) == false){
              tempOutputDate = addDays(tempOutputDate, parseInt(addDay))
            }

            setOutputDate(tempOutputDate)
            console.log("no error")
        }
    }
  },[year, month, day, addYear, addMonth, addDay])
  
  function processMomentDate(momentObject){
    console.log(momentObject)
    setYear(momentObject.format('YYYY'))
    setMonth(momentObject.format('M'))
    setDay(momentObject.format('D'))
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
          <meta charSet="utf-8" />
          <title>Date Calculator</title>
          <meta name='description' content="A tool to help you add years/months/days to a date. Use the datetime picker to pick a date, or input the date manully, then input the years/months/days you want to add. A final date is then calculated." />
      </Helmet>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="contact-outer center-content tiles-wrap">
            <Container>
              <Row>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  placeholder='yyyy'
                  onChange={(event) => setYear(event.target.value)}
                  value={year} 
                />
                </Col>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  placeholder='mm'
                  onChange={(event) => setMonth(event.target.value)} 
                  value={month}
                />
                </Col>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  placeholder='dd'
                  onChange={(event) => setDay(event.target.value)} 
                  value={day}
                />
                </Col>
              </Row>
              <Row>
                <Col>
                    <Datetime 
                    input={false} 
                    onChange={(moment) => processMomentDate(moment)} 
                    value={inputDate}
                    />
                </Col>
              </Row>
              <Row>
                <Col>+</Col>
              </Row>
              <Row>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  placeholder='years'
                  onChange={(event) => setAddYear(event.target.value)} 
                />
                </Col>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  placeholder='months'
                  onChange={(event) => setAddMonth(event.target.value)} 
                />
                </Col>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  placeholder='days'
                  onChange={(event) => setAddDay(event.target.value)} 
                />
                </Col>
              </Row>
              <Row>
                <Col>↓</Col>
              </Row>
              <Row>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  endAdornment={<InputAdornment position="end">(YYYY-MM-DD)</InputAdornment>}
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "90%"}}
                  disabled
                  value={outputDate ? format(outputDate,"yyyy/MM/dd") : ""}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="contact-outer center-content tiles-wrap">
          <Table sx={{ maxWidth: 650, marginTop: "2vh", marginBottom: "2vh" }} aria-label="simple table">
            <TableHead>
              <TableRow style={{backgroundColor:"#3388ff"}}>
                <TableCell align="center" style={{fontWeight: "bold", color: "white"}}>Start Date</TableCell>
                <TableCell align="center" style={{fontWeight: "bold", color: "white"}}>Add</TableCell>
                <TableCell align="center" style={{fontWeight: "bold", color: "white"}}>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[[1,0,0],[2,10,5],[1,50,5],[55,100,5],[0,0,1000]].map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  style={{backgroundColor:"white", border: "1px solid #e0e0e0"}}
                >
                  {index == 0 && <TableCell rowSpan={5} align="center"><p>Today</p> <p>({moment().format("YYYY/MM/DD")})</p></TableCell>}
                  <TableCell align="center">{row[0] + " years + " + row[1] + " months + " + row[2] + " days"}</TableCell>
                  <TableCell align="center">{moment().add(row[0], 'years').add(row[1], 'months').add(row[2], 'days').format("YYYY/MM/DD")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </div>

          <div className="contact-outer center-content tiles-wrap">
            <CustomFeaturesTiles features={features} />
          </div>
        </div>
      </div>
    </section>
  );
}

DateCalculator.propTypes = propTypes;
DateCalculator.defaultProps = defaultProps;

export default DateCalculator;