import React from 'react';
// import sections
import TaxiConverter from '../components/sections/TaxiConverter';

const Taxi = () => {

  return (
    <>
      <TaxiConverter invertMobile topDivider imageFill className="illustration-section-02" />
    </>
  );
}

export default Taxi;