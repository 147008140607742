import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import {Helmet} from "react-helmet";
import Toollist from '../components/sections/Toollist';

const Home = () => {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Useful Converter</title>
        <meta name='description' content="We provide quality and user-friendly online converters and tools for everyone to use. Our products include Square Feet to Square Meter Converter, Square Meter to Square Feet Converter, Feet to Meters Converter, Meters to Feet Converter, Date Calculator and PDF Merger. Hope you enjoy our products!" />
      </Helmet>
      <Hero className="illustration-section-01" />
      <Toollist className="illustration-section-01" />
      <FeaturesTiles />
      <Testimonial topDivider />
      <Cta split />
    </>
  );
}

export default Home;