import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputAdornment, Input, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CustomFeaturesTiles from './CustomFeaturesTiles';
import {Helmet} from "react-helmet";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const SQM2SQFT = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [sqft, setSqft] = useState("")
  const [sqm, setSqm] = useState("")

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Square Meters to Square Feet Converter\n(m² to ft²)',
    paragraph: 'Convert area in square meters to area in square feet'
  };

  const features = [
    {"title": "Square Meter", "description": "1 square meter (m²) is equal to 10.7639104 square feet (ft²)."},
    {"title": "Square Feet", "description": "1 square feet (ft²) is equal to 0.09290304 square meter (m²)."},
    {"title": "Conversion Table", "description": "You can make reference to the conversion table above."},
    {"title": "Completely Free", "description": "Our applications are completely free. Feel free to use our applications! :)"},
    {"title": "User-Friendly", "description": "Our applications are designed to be as simple as possible. Everyone can get started to use our applications in seconds."},
    {"title": "usefulconverter.com", "description": "You can find various useful converter and tools on usefulconverter.com"},
  ]

  useEffect(() => {
    if (!isNaN(parseFloat(sqft))){
        setSqm((sqft*10.76391041671).toFixed(6))
    } else {
      setSqm("")
    }
  },[sqft])

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Square Meters to Square Feet Converter</title>
        <meta name='description' content="Convert area in square meters to area in square feet (m² to ft²). 1 square feet (ft²) is equal to 0.09290304 square meter (m²). 1 square meter (m²) is equal to 10.7639104 square feet (ft²). Our applications are completely free. Feel free to use our applications! :)" />
      </Helmet>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="contact-outer center-content tiles-wrap">
            <Container>
              <Row>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  endAdornment={<InputAdornment position="end">m²</InputAdornment>}
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "90%"}}
                  onChange={(event) => setSqft(event.target.value)} 
                />
                </Col>
              </Row>
              <Row>
                <Col>↓</Col>
              </Row>
              <Row>
                <Col>
                <Input
                  id="standard-adornment-weight"
                  endAdornment={<InputAdornment position="end">ft²</InputAdornment>}
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "90%"}}
                  disabled
                  value={sqm}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="contact-outer center-content tiles-wrap">
          <Table sx={{ maxWidth: 650, marginTop: "2vh", marginBottom: "2vh" }} aria-label="simple table">
            <TableHead>
              <TableRow style={{backgroundColor:"#3388ff"}}>
                <TableCell align="center" style={{fontWeight: "bold", color: "white"}}>Area in square meter (m²)</TableCell>
                <TableCell align="center" style={{fontWeight: "bold", color: "white"}}>Area in square feet (ft²)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[1,10,50,100,1000].map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  style={{backgroundColor:"white", border: "1px solid #e0e0e0"}}
                >
                  <TableCell align="center">{row}</TableCell>
                  <TableCell align="center">{(row*10.76391041671).toFixed(6)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </div>

          <div className="contact-outer center-content tiles-wrap">
            <CustomFeaturesTiles features={features} />
          </div>
        </div>
      </div>
    </section>
  );
}

SQM2SQFT.propTypes = propTypes;
SQM2SQFT.defaultProps = defaultProps;

export default SQM2SQFT;