import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {Helmet} from "react-helmet";
import {Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const TaxiGuide = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const startingfareRED = 27
  const firstjumpingfareRED = 1.9
  const secondjumpingfareRED = 1.3
  const jumpposition1RED = 2
  const jumpposition2RED = 9

  //static variable for green taxi 
  const startingfareGREEN = 23.5
  const firstjumpingfareGREEN = 1.7
  const secondjumpingfareGREEN = 1.3
  const jumpposition1GREEN = 2
  const jumpposition2GREEN = 8

  //static variable for blue taxi 
  const startingfareBLUE = 22
  const firstjumpingfareBLUE = 1.7
  const secondjumpingfareBLUE = 1.5
  const jumpposition1BLUE = 2
  const jumpposition2BLUE = 20

  const sectionHeader = {
    title: 'Hong Kong Taxi Guide',
    paragraph: 'All you need to know about hailing a taxi in Hong Kong, especially for tourists.'
  };

  const sectionHeader2 = {
    title: 'Three type of taxis',
    paragraph: 'There are three type of taxi in Hong Kong, Urban (Red) Taxi, New Territories (Green) Taxi and Lantau (Blue) Taxi.'
  };

  const sectionHeader3 = {
    title: 'Urban (Red) Taxi',
    paragraph: 'A red taxi can go all around Hong Kong (Kowloon, Hong Kong, New Territories and Lantau Island) except the southern part of Lantau Island (The Big Buddha, Tai O, Ngong Ping and Mui Wo). If you would like to go to the southern part of Lantau Island, you need to take a Lantau (Blue) Taxi.'
  };

  const sectionHeader4 = {
    title: 'New Territories (Green) Taxi',
    paragraph: 'A green taxi can only take passengers around certain part of the New Territories (Tuen Mun, Yuen Long, Tin Shui Wai, Tai Po, Sheung Shui, Sai Kung). Since there are only a few tourist spot in the New Territories, it is very unlikely that a tourist would take a green taxi😂.'
  };

  const sectionHeader5 = {
    title: 'Lantau (Blue) Taxi',
    paragraph: 'A lantau taxi can only goes around the Lantau Isalnd. If you would like to go to the southern part of Lantau Island (The Big Buddha, Tai O, Ngong Ping and Mui Wo), you need to take a Lantau (Blue) Taxi.'
  };

  const sectionHeader6 = {
    title: 'Fare',
    paragraph: 'The taxi fare is quite cheap in Hong Kong, starting from HKD$22 to $27 (<USD$4).'
  };

  const sectionHeader7 = {
    title: 'Waiting time',
    paragraph: 'There is a waiting time charge ranging from 1.3 to 1.9, depending on the travel distance and the type of taxi.'
  };

  const sectionHeader8 = {
    title: 'Additional Charges',
    paragraph: 'There are additional charges for baggages ($6/baggage), pets($5/pet) etc.'
  };

  const sectionHeader9 = {
    title: 'Toll Tunnel Fee',
    paragraph: 'There are several toll tunnel in Hong Kong. When the taxi drives pass these tunnels, the passeneger needs to pay for the fee. For cross harbour tunnel (Cross-Harbour Tunnel, Eastern Harbour Crossing, Western Harbour Crossing), the passenger needs to pay the toll fee plus the return fee ($10 - $15).'
  };

  const sectionHeader10 = {
    title: 'Hailing a taxi',
    paragraph: 'Just raise your shoulder and a taxi would stop in front of you. You can also use apps like Uber or Hong Kong Taxi to hail a cab.'
  };


  const sectionHeaderLast = {
    title: 'Hong Kong Taxi',
    paragraph: 'Hope you enjoy taking the iconic Hong Kong Taxi!'
  };

  function calfareinput(startingfare, firstjumpingfare, secondjumpingfare, jumpposition1, jumpposition2, inputdistance, inputminute, inputadditionalfare){
    if (inputdistance==null){
      return
    } else {
      var temp_inputdistance_cal = (Math.ceil(inputdistance * 5) / 5).toFixed(1)
    }

    if (inputminute==null){
      var inputminute_cal = 0
    } else {
      var inputminute_cal = Math.floor(inputminute)*0.2
    }
    
    if ([null,""," ", undefined].includes(inputadditionalfare)){
      var inputadditionalfare_cal = 0
    } else {
      var inputadditionalfare_cal = parseInt(inputadditionalfare)
    }

    var inputdistance_cal = parseFloat(temp_inputdistance_cal) + parseFloat(inputminute_cal)


    if (inputdistance<=jumpposition1){
      var tempnewfare = startingfare + inputadditionalfare_cal
    } else if ((inputdistance>=jumpposition1 && inputdistance<=jumpposition2)){
      var tempnewfare = startingfare + (inputdistance_cal - jumpposition1) / 0.2 * firstjumpingfare + inputadditionalfare_cal
    } else if (inputdistance > jumpposition2)  {
      var tempnewfare = startingfare + (jumpposition2 - jumpposition1) / 0.2 * firstjumpingfare + (inputdistance_cal - jumpposition2) / 0.2 * secondjumpingfare + inputadditionalfare_cal
    }

    return Math.round(tempnewfare*10)/10
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hong Kong Taxi Gudie</title>
        <meta name='description' content="All you need to know about hailing a taxi in Hong Kong, especially for tourists. There are three type of taxi in Hong Kong, Urban (Red) Taxi, New Territories (Green) Taxi and Lantau (Blue) Taxi. A red taxi can go all around Hong Kong except the southern part of Lantau Island (The Big Buddha, Tai O, Ngong Ping and Mui Wo). If you would like to go to the southern part of Lantau Island, you need to take a Lantau (Blue) Taxi." />
      </Helmet>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content  reveal-from-bottom" />
          <SectionHeader data={sectionHeader2} className="center-content  reveal-from-bottom" />
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/taxi-three-color.png')}
            alt="friends eating lunch together"
            width={696}
            height={504} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeader3} className="center-content  reveal-from-bottom" />
          </div>
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/red-taxi.png')}
            alt="Urban (Red) Taxi"
            width={696}
            height={504} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeader4} className="center-content  reveal-from-bottom" />
          </div>
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/green-taxi.png')}
            alt="New Territories (Green) Taxi"
            width={696}
            height={504} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeader5} className="center-content  reveal-from-bottom" />
          </div>
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/blue-taxi.png')}
            alt="Lantau (Blue) Taxi"
            width={696}
            height={504} />
          </div>
        </div>
        <div className='about-inner'>
          <SectionHeader data={sectionHeader6} className="center-content  reveal-from-bottom" />
        </div>
        <div className="contact-outer center-content tiles-wrap">
            <Table sx={{ maxWidth: 650, marginTop: "2vh", marginBottom: "2vh"}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "black"}}>Distance (km)</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "red"}}>Urban (Red) Taxi</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "green"}}>New Territories (Green) Taxi</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "#26b1e7"}}>Lantau (Blue) Taxi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1,5,10,15,20,25,30,40,50].map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{backgroundColor:"white", border: "1px solid #e0e0e0"}}
                  >
                    <TableCell align="center">{row}</TableCell>
                    <TableCell align="center">{calfareinput(startingfareRED, firstjumpingfareRED, secondjumpingfareRED, jumpposition1RED, jumpposition2RED, row, 0, 0)}</TableCell>
                    <TableCell align="center">{calfareinput(startingfareGREEN, firstjumpingfareGREEN, secondjumpingfareGREEN, jumpposition1GREEN, jumpposition2GREEN, row, 0, 0)}</TableCell>
                    <TableCell align="center">{calfareinput(startingfareBLUE, firstjumpingfareBLUE, secondjumpingfareBLUE, jumpposition1BLUE, jumpposition2BLUE, row, 0, 0)}</TableCell>
                  </TableRow>
                ))}
                <TableRow style={{backgroundColor: "white"}}>
                  <TableCell colSpan={4} align="center">*Assuming no waiting time and surcharge</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>   
          <div className='about-inner'>
            <SectionHeader data={sectionHeader7} className="center-content  reveal-from-bottom" />
          </div>
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/clock.png')}
            alt="hong kong taxi waiting time"
            width={296}
            height={296} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeader8} className="center-content  reveal-from-bottom" />
          </div>
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/baggage.png')}
            alt="baggage"
            width={356}
            height={356} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeader9} className="center-content  reveal-from-bottom" />
          </div>
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/tunnel.jpg')}
            alt="Western Harbour Crossing"
            width={696}
            height={504} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeader10} className="center-content  reveal-from-bottom" />
          </div>
          <div className="reveal-from-bottom center-content" data-reveal-value="20px" data-reveal-delay="400">
            <Image
            className="has-shadow"
            src={require('./../../assets/images/ubertaxi.jpeg')}
            alt="Uber Taxi"
            width={696}
            height={504} />
          </div>
          <div className='about-inner'>
            <SectionHeader data={sectionHeaderLast} className="center-content  reveal-from-bottom" />
          </div>
      </div>
    </section>
  );
}

TaxiGuide.propTypes = propTypes;
TaxiGuide.defaultProps = defaultProps;

export default TaxiGuide;