import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { InputAdornment, Input, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomFeaturesTiles from './CustomFeaturesTiles';
import {Helmet} from "react-helmet";
import Banner from '../layout/partials/Banner';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const TaxiConverter = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '香港的士收費計算機',
    paragraph: '輸入收費公里,時間及附加費以計算車費'
  };

  const startingfareRED = 29
  const firstjumpingfareRED = 2.1
  const secondjumpingfareRED = 1.4
  const jumpposition1RED = 2
  const jumpposition2RED = 9

  //static variable for green taxi 
  const startingfareGREEN = 25.5
  const firstjumpingfareGREEN = 1.9
  const secondjumpingfareGREEN = 1.4
  const jumpposition1GREEN = 2
  const jumpposition2GREEN = 8

  //static variable for blue taxi 
  const startingfareBLUE = 24
  const firstjumpingfareBLUE = 1.9
  const secondjumpingfareBLUE = 1.6
  const jumpposition1BLUE = 2
  const jumpposition2BLUE = 20

  //user input variables
  const [distance, setDistance] = useState()
  const [minute, setMinute] = useState()
  const [additionalfare, setAdditionalfare] = useState()
  const [newfarered, setNewfarered] = useState()
  const [newfaregreen, setNewfaregreen] = useState()
  const [newfareblue, setNewfareblue] = useState()
  const [errormessage, setErrormessage] = useState(false)

  const features = [
    {"title": "香港的士", "description": "香港的士是香港其中一種主要交通工具,的士分為三種,分別為市區的士,新界的士及大嶼山的士"},
    {"title": "市區的士", "description": "只在市區,新界及大嶼山部分地區(不能進入石甲門以南的大嶼山南部地區)行走"},
    {"title": "新界的士", "description": "只在新界西北及東北行走,不可在荃灣,沙田及將軍澳自由上落客,但亦可在部分指定的士站上落客(如 順利邨,荃灣地鐵站,青衣機鐵站,沙田威爾斯醫院,沙田醫院,坑口站,新寶城,香港國際機場,港珠澳大橋)"},
    {"title": "大嶼山的士", "description": "只在大嶼山行走"},
    {"title": "收費", "description": "市區的士,新界的士及大嶼山的士的收費準則各有不同,市區的士$29起標,新界的士$25.5起標,大嶼山的士$24起標"},
    {"title": "附加費", "description": "香港的士會對以下服務加收附加費,包括行李(每件$6),動物(每隻$5),及隧道費及回程費"},
    {"title": "紅磡海底隧道", "description": "單程隧道費: $25 隧道費連回程費: $25"},
    {"title": "東區海底隧道", "description": "單程隧道費: $25 隧道費連回程費: $25"},
    {"title": "西區海底隧道", "description": "單程隧道費: $25 隧道費連回程費: $25"},
    {"title": "香港仔隧道", "description": "隧道費: $5"},
    {"title": "城門隧道", "description": "隧道費: $5"},
    {"title": "尖山隧道", "description": "隧道費: $8"},
    {"title": "獅子山隧道", "description": "隧道費: $8"},
    {"title": "大老山隧道", "description": "隧道費: $20"},
    {"title": "大欖隧道", "description": "隧道費: $58"},
  ]

  function calfare(startingfare, firstjumpingfare, secondjumpingfare, jumpposition1, jumpposition2){
    if (distance==null){
      setErrormessage("請輸入收費公里")
      return
    } else {
      var temp_distance_cal = (Math.ceil(distance * 5) / 5).toFixed(1)
    }

    if (minute==null){
      var minute_cal = 0
    } else {
      var minute_cal = Math.floor(minute)*0.2
    }
    
    if ([null,""," ", undefined].includes(additionalfare)){
      var additionalfare_cal = 0
    } else {
      var additionalfare_cal = parseInt(additionalfare)
    }

    console.log(typeof minute_cal)

    var distance_cal = parseFloat(temp_distance_cal) + parseFloat(minute_cal)

    console.log("temp distance: " + temp_distance_cal.toString())
    console.log("distance: " + distance_cal.toString())
    console.log("additional fare: " + additionalfare_cal.toString())

    if (distance<=jumpposition1){
      var tempnewfare = startingfare + additionalfare_cal
    } else if ((distance>=jumpposition1 && distance<=jumpposition2)){
      var tempnewfare = startingfare + (distance_cal - jumpposition1) / 0.2 * firstjumpingfare + additionalfare_cal
    } else if (distance > jumpposition2)  {
      var tempnewfare = startingfare + (jumpposition2 - jumpposition1) / 0.2 * firstjumpingfare + (distance_cal - jumpposition2) / 0.2 * secondjumpingfare + additionalfare_cal
    }
    console.log(tempnewfare)
    setErrormessage()
    
    var ans = isNaN(tempnewfare) ? "" : Math.round(tempnewfare*10)/10
    return ans
  }
  

  function calallfare(){
    const tempredfare = calfare(startingfareRED, firstjumpingfareRED, secondjumpingfareRED, jumpposition1RED, jumpposition2RED)
    setNewfarered(tempredfare)
    const tempgreenfare = calfare(startingfareGREEN, firstjumpingfareGREEN, secondjumpingfareGREEN, jumpposition1GREEN, jumpposition2GREEN)
    setNewfaregreen(tempgreenfare)
    const tempbluefare = calfare(startingfareBLUE, firstjumpingfareBLUE, secondjumpingfareBLUE, jumpposition1BLUE, jumpposition2BLUE)
    setNewfareblue(tempbluefare)
  }

  function calfareinput(startingfare, firstjumpingfare, secondjumpingfare, jumpposition1, jumpposition2, inputdistance, inputminute, inputadditionalfare){
    if (inputdistance==null){
      setErrormessage("請輸入收費公里")
      return
    } else {
      var temp_inputdistance_cal = (Math.ceil(inputdistance * 5) / 5).toFixed(1)
    }

    if (inputminute==null){
      var inputminute_cal = 0
    } else {
      var inputminute_cal = Math.floor(inputminute)*0.2
    }
    
    if ([null,""," ", undefined].includes(inputadditionalfare)){
      var inputadditionalfare_cal = 0
    } else {
      var inputadditionalfare_cal = parseInt(inputadditionalfare)
    }

    var inputdistance_cal = parseFloat(temp_inputdistance_cal) + parseFloat(inputminute_cal)


    if (inputdistance<=jumpposition1){
      var tempnewfare = startingfare + inputadditionalfare_cal
    } else if ((inputdistance>=jumpposition1 && inputdistance<=jumpposition2)){
      var tempnewfare = startingfare + (inputdistance_cal - jumpposition1) / 0.2 * firstjumpingfare + inputadditionalfare_cal
    } else if (inputdistance > jumpposition2)  {
      var tempnewfare = startingfare + (jumpposition2 - jumpposition1) / 0.2 * firstjumpingfare + (inputdistance_cal - jumpposition2) / 0.2 * secondjumpingfare + inputadditionalfare_cal
    }

    return Math.round(tempnewfare*10)/10
  }

  useEffect(() => {
    calallfare()
  },[distance, minute, additionalfare])

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>香港的士收費計算機</title>
        <meta name='description' content="計算香港的士車費的好工具,計算市區的士,新界的士及大嶼山的士的收費,並提供關於香港的士的各種資訊,市區的士,新界的士及大嶼山的士的收費準則各有不同,市區的士$27起標,新界的士$23.5起標,大嶼山的士$22起標,香港的士會對以下服務加收附加費,包括行李(每件$6),動物(每隻$5),及隧道費及回程費" />
      </Helmet>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          {/*<Banner />*/}
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="contact-outer center-content tiles-wrap">
            <Container>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0">
                    行車公里 (km)
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  endAdornment={<InputAdornment position="end">km</InputAdornment>}
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  onChange={(event) => setDistance(event.target.value)}
                />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0">
                    收費分鐘 (min)
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  endAdornment={<InputAdornment position="end">min</InputAdornment>}
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  onChange={(event) => setMinute(event.target.value)}
                />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0">
                    附加費 ($)
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  onChange={(event) => setAdditionalfare(event.target.value)}
                />
                </Col>
              </Row>
              <Row>
                <Col>↓</Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0" style={{color: "#ff0000"}}>
                    市區的士
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  disabled
                  value={newfarered}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#ff0000",
                      textAlign: "center"
                    },
                  }}
                />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0" style={{color: "#008000"}}>
                    新界的士
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  disabled
                  value={newfaregreen}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#008000",
                      textAlign: "center"
                    },
                  }}
                />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0" style={{color: "#26b1e7"}}>
                    大嶼山的士
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  disabled
                  value={newfareblue}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#26b1e7",
                      textAlign: "center",
                    },
                  }}
                />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="contact-outer center-content tiles-wrap">
            <Table sx={{ maxWidth: 650, marginTop: "2vh", marginBottom: "2vh"}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "black"}}>公里</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "red"}}>市區的士收費</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "green"}}>新界的士收費</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "#26b1e7"}}>大嶼山的士收費</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1,5,10,15,20,25,30,40,50].map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{backgroundColor:"white", border: "1px solid #e0e0e0"}}
                  >
                    <TableCell align="center">{row}</TableCell>
                    <TableCell align="center">{calfareinput(startingfareRED, firstjumpingfareRED, secondjumpingfareRED, jumpposition1RED, jumpposition2RED, row, 0, 0)}</TableCell>
                    <TableCell align="center">{calfareinput(startingfareGREEN, firstjumpingfareGREEN, secondjumpingfareGREEN, jumpposition1GREEN, jumpposition2GREEN, row, 0, 0)}</TableCell>
                    <TableCell align="center">{calfareinput(startingfareBLUE, firstjumpingfareBLUE, secondjumpingfareBLUE, jumpposition1BLUE, jumpposition2BLUE, row, 0, 0)}</TableCell>
                  </TableRow>
                ))}
                <TableRow style={{backgroundColor: "white"}}>
                  <TableCell colSpan={4} align="center">*以上收費假設沒有收費分鐘及附加費</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className="contact-outer center-content tiles-wrap">
            <CustomFeaturesTiles features={features} />
          </div>
        </div>
      </div>
    </section>
  );
}

TaxiConverter.propTypes = propTypes;
TaxiConverter.defaultProps = defaultProps;

export default TaxiConverter;