import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {Helmet} from "react-helmet";
import { Input, TableBody, TableCell, TableHead, TableRow } from '@mui/material';


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const AEWaiting = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [waitTime, setWaitTime] = useState([])

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: '香港急症室等候時間',
    paragraph: '最長等候時間顯示上限為8小時，表示急症室正處理大量等候已久的病人。病況輕微的病人可考慮使用私營醫療服務（基層醫療指南或香港醫生網）。'
  };

  const getWaitingTime = async () => {
    fetch('/api/opendata/aed/aedwtdata-tc.json')
    .then((response) => response.json())
    .then((data) => {
       console.log(data);
       setWaitTime(data["waitTime"])
    })
    .catch((err) => {
       console.log("error")
       console.log(err.message);
    });
  }

  useEffect(() => {
    getWaitingTime()
  },[])

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>香港急症室等候時間</title>
        <meta name='description' content="最長等候時間顯示上限為8小時，表示急症室正處理大量等候已久的病人。病況輕微的病人可考慮使用私營醫療服務（基層醫療指南或香港醫生網）。" />
      </Helmet>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content  reveal-from-bottom" />
          
          <div className="contact-outer center-content tiles-wrap">
            <div>
            <TableHead>
              <TableRow style={{backgroundColor:"#ffffff"}}>
                <TableCell>
                  醫院
                </TableCell>
                <TableCell>
                  等候時間
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {waitTime.map(item => {return(
                <TableRow style={{backgroundColor:"#ffffff"}}>
                  <TableCell>
                    {item["hospName"]}
                  </TableCell>
                  <TableCell>
                    {item["topWait"]}
                  </TableCell>
                </TableRow>
              )})}
            </TableBody>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

AEWaiting.propTypes = propTypes;
AEWaiting.defaultProps = defaultProps;

export default AEWaiting;