import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import About from './views/About';
import Contact from './views/Contact';
import Taxi from './views/Taxi';
import SQFT2SQM from './components/sections/SQFT2SQM';
import SQM2SQFT from './components/sections/SQM2SQFT';
import FT2M from './components/sections/FT2M';
import M2FT from './components/sections/M2FT';
import DateCalculator from './components/sections/DateCalculator';
import MergePDF from './components/sections/MergePDF';
import TaxiExam from './components/sections/TaxiExam';
import TaxiGuide from './components/sections/TaxiGuide';
import Blog from './components/sections/Blog';
import AEWaiting from './components/sections/AEWaiting';
import ChequeConverter from './components/sections/ChequeConverter';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault} />
          <AppRoute exact path="/about" component={About} layout={LayoutDefault} />
          <AppRoute exact path="/taxi-calculator" component={Taxi} layout={LayoutDefault} />
          <AppRoute exact path="/taxi-exam" component={TaxiExam} layout={LayoutDefault} />
          <AppRoute exact path="/square-feet-to-square-meter" component={SQFT2SQM} layout={LayoutDefault} />
          <AppRoute exact path="/square-meter-to-square-feet" component={SQM2SQFT} layout={LayoutDefault} />
          <AppRoute exact path="/feet-to-meters" component={FT2M} layout={LayoutDefault} />
          <AppRoute exact path="/meters-to-feet" component={M2FT} layout={LayoutDefault} />
          <AppRoute exact path="/date-calculator" component={DateCalculator} layout={LayoutDefault} />
          <AppRoute exact path="/mergepdf" component={MergePDF} layout={LayoutDefault} />
          <AppRoute exact path="/taxi-guide-hk" component={TaxiGuide} layout={LayoutDefault} />
          <AppRoute exact path="/blog" component={Blog} layout={LayoutDefault} />
          <AppRoute exact path="/support" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/ae-waiting" component={AEWaiting} layout={LayoutDefault} />
          <AppRoute exact path="/cheque-writing" component={ChequeConverter} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;