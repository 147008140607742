import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {Helmet} from "react-helmet";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const Blog = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Blog',
    paragraph: 'Check out our latest blog posts😀.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog</title>
        <meta name='description' content="Check out our latest blog posts😀. Hong Kong Taxi Guide. All you need to know about hailing a taxi in Hong Kong, especially for tourists." />
      </Helmet>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="contact-outer center-content tiles-wrap">

            <div className="contact-inner tiles-item">
              <a href="/taxi-guide-hk">
                
                  <Image
                  className="has-shadow"
                  src={require('./../../assets/images/taxi-blog-cover.png')}
                  alt="taxi blog"
                  width={696}
                  height={504} />
                <p className='contact-text'>Hong Kong Taxi Guide</p>
                <p className='contact-text-small'>All you need to know about hailing a taxi in Hong Kong, especially for tourists.</p>
              </a>
            </div>

            <div className="contact-inner tiles-item">
              <a href="/taxi-guide-hk">
                
                  <Image
                  className="has-shadow"
                  src={require('./../../assets/images/taxi-blog-cover.png')}
                  alt="taxi blog"
                  width={696}
                  height={504} />
                <p className='contact-text'>Hong Kong Taxi Guide</p>
                <p className='contact-text-small'>All you need to know about hailing a taxi in Hong Kong, especially for tourists.</p>
              </a>
            </div>

            <div className="contact-inner tiles-item">
              <a href="/taxi-guide-hk">
                
                  <Image
                  className="has-shadow"
                  src={require('./../../assets/images/taxi-blog-cover.png')}
                  alt="taxi blog"
                  width={696}
                  height={504} />
                <p className='contact-text'>Hong Kong Taxi Guide</p>
                <p className='contact-text-small'>All you need to know about hailing a taxi in Hong Kong, especially for tourists.</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Blog.propTypes = propTypes;
Blog.defaultProps = defaultProps;

export default Blog;