import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputAdornment, Input, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CustomFeaturesTiles from './CustomFeaturesTiles';
import { FilePond, registerPlugin } from 'react-filepond'
import './filepond.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import PDFMerger from 'pdf-merger-js/browser';
import { Button } from 'react-bootstrap';
import {Helmet} from "react-helmet";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const MergePDF = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [files, setFiles] = useState([])
  const [mergedPdfUrl, setMergedPdfUrl] = useState()

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Merge/Combine PDF',
    paragraph: 'Select or drag multiple PDF files and merge them with a single click for FREE. As simple as that!'
  };

  const features = [
    {"title": "PDF", "description": "PDF is a file format to present documents, text and images that can be opened on all devices."},
    {"title": "PDF Merger", "description": "This PDF Merger can help you combine different PDF files into a single file."},
    {"title": "Privacy", "description": "This tool does not require uploading of documents. The file conversion is done on the client side. We cannot see the files you have merged."},
    {"title": "Completely Free", "description": "Our applications are completely free. Feel free to use our applications! :)"},
    {"title": "User-Friendly", "description": "Our applications are designed to be as simple as possible. Everyone can get started to use our applications in seconds."},
    {"title": "usefulconverter.com", "description": "You can find various useful converter and tools on usefulconverter.com"},
  ]

  var merger = new PDFMerger();

  async function downloadPDF(){
    if (files){
      const render = async () => {
        for(const file of files) {
          await merger.add(file.file)
        }

        const mergedPdf = await merger.saveAsBlob();
        const url = URL.createObjectURL(mergedPdf);
        await merger.save('merged.pdf')
        return setMergedPdfUrl(url);
      };

      render()
    }
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Merge PDF</title>
        <meta name='description' content="This PDF Merger can help you combine different PDF files into a single file. Our applications are completely free. Feel free to use our applications! This tool does not require uploading of documents. The file conversion is done on the client side. We cannot see the files you have merged." />
      </Helmet>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="contact-outer center-content tiles-wrap">
            <Container>
              <Row>
                <Col>
                <FilePond
                    files={files}
                    allowReorder={true}
                    allowMultiple={true}
                    onupdatefiles={setFiles}
                    labelIdle='Drag & Drop your PDF files or <span class="filepond--label-action">Browse</span>'
                />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button onClick={() => downloadPDF()}>Download combined PDF</Button>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="contact-outer center-content tiles-wrap">
            <CustomFeaturesTiles features={features} />
          </div>
        </div>
      </div>
    </section>
  );
}

MergePDF.propTypes = propTypes;
MergePDF.defaultProps = defaultProps;

export default MergePDF;