import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { InputAdornment, Input, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomFeaturesTiles from './CustomFeaturesTiles';
import {Helmet} from "react-helmet";
import { toWords } from 'number-to-words';
import * as converter from 'number-to-chinese-words'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const ChequeConverter = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '支票中英文金額大寫轉換器',
    paragraph: '輸入支票金額,換成中英文大寫'
  };

  //user input variables
  const [amount, setAmount] = useState()
  const [chiletter, setChiletter] = useState()
  const [engletter, setEngletter] = useState()

  const features = [
    {"title": "為甚麼要大寫?", "description": "支票要大寫的原因是為了增加其可讀性和減少錯誤。大寫字母更易於辨認，尤其是在手寫時更為清晰。使用大寫字母可以減少字母之間的混雜和誤解，從而減少發生錯誤的可能性。此外，大寫字母更具有標準化，可以在不同的人員之間保持一定的一致性。由於支票是金融文件，確保其準確性非常重要，因此採用大寫字母是一種有效的方式。"},
    {"title": "如果支票不用大寫會怎樣?", "description": "銀行無法正確辨認金額：在支票上標明金額時，通常以大寫字母來表示，這有助於避免出現誤解或篡改。如果沒有使用大寫字母，銀行可能會難以確定支票的正確金額。銀行可能無法辨認受款人：支票上的受款人名稱通常也是以大寫字母顯示的，這有助於消除歧義並確保支票被支付給正確的受款人。如果不使用大寫字母，銀行可能會有困難辨認受款人的姓名，從而導致支付錯誤。法律規定：在一些地區，使用大寫字母填寫支票是法律規定的要求。如果違反該規定，可能會導致支票無效或無法兌現。因此，為了避免麻煩並確保支票能夠正常兌現，使用大寫字母填寫支票是一個重要的步驟。"},
    {"title": "支票中英文金額大寫轉換器", "description": "支票中英文金額大寫轉換器是一個方便的工具，可以將數字金額轉換為中英文大寫金額。當填寫支票或其他金融文件時，正確填寫金額是非常重要的，這個工具能夠確保您填寫的金額是準確無誤的。這款支票中英文金額大寫轉換器是一個非常實用的工具，可以適用於不同需求的用戶，無論是在個人日常生活中填寫支票，還是在商業金融領域中處理大量金額，它都可以為您提供幫助，確保您的金額填寫準確無誤。"},
    {"title": "精確轉換", "description": "本工具能夠根據您輸入的數字金額進行精確的轉換，確保轉換結果準確無誤。"},
    {"title": "中英文轉換", "description": "不僅可以將金額轉換為英文大寫，還可以將金額轉換為中文大寫。這使得它成為適用於不同語言環境和需求的工具。"},
    {"title": "界面友好", "description": "本工具具有使用方便的界面，使得操作簡單直觀。您只需輸入金額，選擇轉換為中文或英文，然後點擊轉換按鈕即可得到轉換結果。"},
  ]

  converter.default.labels = Object.assign({},converter.default.labels, {
    digits : ['零','壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖'],
    units: ['','拾', '佰', '仟', '萬', '拾', '佰', '仟', '億', '拾', '佰', '仟', '兆', '拾', '佰', '仟', '京', '拾', '佰', '仟', '垓']
  })

  function convertEng(amount) {
    try {
      const word = toWords(amount)
      var tempword = word.split(" ").map(item => {return item[0].toUpperCase() + item.substring(1)}).join(" ").split("-").map(item => {return item[0].toUpperCase() + item.substring(1)}).join("-")
      

      var centword = amount.split(".").length > 1 && amount.split(".")[1].length>=1
                    ? 
                    amount.split(".")[1].length == 1
                    ?
                    toWords(amount.split(".")[1][0] + "0").split(" ").map(item => {return item[0].toUpperCase() + item.substring(1)}).join(" ").split("-").map(item => {return item[0].toUpperCase() + item.substring(1)}).join("-")
                    :
                    toWords(amount.split(".")[1][0] + amount.split(".")[1][1]).split(" ").map(item => {return item[0].toUpperCase() + item.substring(1)}).join(" ").split("-").map(item => {return item[0].toUpperCase() + item.substring(1)}).join("-")
                    : ""

      const finalword = centword=="" ? tempword + " Dollars Only" : tempword + " Dollars and " + centword + " Cents Only"
      return finalword
    } catch {
      setAmount(0)
      setEngletter("")
    }
  }

  function convertEngStatic(amount) {
    try {
      const word = toWords(amount)
      var tempword = word.split(" ").map(item => {return item[0].toUpperCase() + item.substring(1)}).join(" ").split("-").map(item => {return item[0].toUpperCase() + item.substring(1)}).join("-") + " Dollars Only"
      
      return tempword
    } catch {
      setAmount(0)
      setEngletter("")
    }
  }

  function convertChi(amount) {
    try {
      const word = converter.toWords(amount.split(".")[0])
      console.log(word)
      var tempword = word

      var centword = amount.split(".").length > 1 && amount.split(".")[1].length>=1
                    ? 
                    amount.split(".")[1].length == 1
                    ?
                    converter.toWords(amount.split(".")[1][0]) + "角"
                    :
                    converter.toWords(amount.split(".")[1][0]) + "角" + converter.toWords(amount.split(".")[1][1]) + "分"
                    : ""

      const finalword = centword=="" ? tempword + "元正" : tempword + "元" + centword
      
      return finalword
    } catch {
      setAmount(0)
      setChiletter("")
    }
  }

  function convertChiStatic(amount) {
    try {
      const word = converter.toWords(amount)
      console.log(word)
      var tempword = word
      
      return tempword + "元正"
    } catch {
      setAmount(0)
      setChiletter("")
    }
  }

  useEffect(() => {
    if (isNaN(parseInt(amount)) == false) {
      setEngletter(convertEng(amount))
      setChiletter(convertChi(amount))
    }
  },[amount])


  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>支票中英文金額大寫轉換器</title>
        <meta name='description' content="輸入支票金額,換成中英文大寫。支票要大寫的原因是為了增加其可讀性和減少錯誤。大寫字母更易於辨認，尤其是在手寫時更為清晰。使用大寫字母可以減少字母之間的混雜和誤解，從而減少發生錯誤的可能性。此外，大寫字母更具有標準化，可以在不同的人員之間保持一定的一致性。由於支票是金融文件，確保其準確性非常重要，因此採用大寫字母是一種有效的方式。支票中英文金額大寫轉換器是一個方便的工具，可以將數字金額轉換為中英文大寫金額。當填寫支票或其他金融文件時，正確填寫金額是非常重要的，這個工具能夠確保您填寫的金額是準確無誤的。這款支票中英文金額大寫轉換器是一個非常實用的工具，可以適用於不同需求的用戶，無論是在個人日常生活中填寫支票，還是在商業金融領域中處理大量金額，它都可以為您提供幫助，確保您的金額填寫準確無誤。" />
      </Helmet>
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="contact-outer center-content tiles-wrap">
            <Container>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0">
                    支票金額
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  onChange={(event) => setAmount(event.target.value)}
                  value = {amount}
                />
                </Col>
              </Row>
              <Row>
                <Col>↓</Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0">
                    中文大寫寫法
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  disabled
                  value={chiletter}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                      textAlign: "center"
                    },
                  }}
                />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="mt-0 mb-0">
                    英文大寫寫法
                  </h4>
                <Input
                  id="standard-adornment-weight"
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{backgroundColor: "#FFFFFF", width: "100%"}}
                  disabled
                  value={engletter}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                      textAlign: "center"
                    },
                  }}
                />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="contact-outer center-content tiles-wrap">
            <Table sx={{ maxWidth: 650, marginTop: "2vh", marginBottom: "2vh"}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "black"}}>支票金額</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "red"}}>中文大寫寫法</TableCell>
                  <TableCell align="center" style={{fontWeight: "bold", color: "white", backgroundColor: "green"}}>英文大寫寫法</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[500,1000,5000,10000,15000,20000,50000,75000,100000,500000,1000000].map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{backgroundColor:"white", border: "1px solid #e0e0e0"}}
                  >
                    <TableCell align="center">{row}</TableCell>
                    <TableCell align="center">{convertChiStatic(row)}</TableCell>
                    <TableCell align="center">{convertEngStatic(row)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="contact-outer center-content tiles-wrap">
            <CustomFeaturesTiles features={features} />
          </div>
        </div>
      </div>
    </section>
  );
}

ChequeConverter.propTypes = propTypes;
ChequeConverter.defaultProps = defaultProps;

export default ChequeConverter;